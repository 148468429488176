* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #000000;
  overflow: hidden;
}

.skipBtn {
  position: absolute;
  bottom: 50px;
  color: #fff;
  background-color: transparent;
  padding: 2px;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 2px;
  border-color: #fff;
  cursor: pointer;
}

.loading-page {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 4;
  background: #000000;
}

.video-page {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  z-index: 4;
  background: #000000;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
  color: #ffffff;
}

.loading-logo {
  width: 300px;
}

.loading-bar {
  margin-top: 30px;
  height: 1px;
  border-radius: 10px;
  background-color: #a2a0a0;
}

.mail {
  position: absolute;
  bottom: 30px;
  right: 30px;
  color: #ffffff;
}

.title {
  position: absolute;
    top: 40px;
    right: 50%;
    transform: translateX(50%);
  z-index: 1;
}

.title-logo {
  width: 150px;
}

.btn-group {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: rgba(31, 30, 30, 0.685);
  backdrop-filter: blur(20px);
  z-index: 1;
  padding: 7px;
}

.btn {
  position: relative;
  border-radius: 100%;
  box-shadow: #a2a0a0;
  padding: 5px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: rgba(63, 61, 61, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn:hover{
  background-color: rgba(112, 109, 109, 0.3);
}

.btn-icon {
  color: #ffffff;
  font-size: 24px;
}

.car-information{
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
  width: 700px;
  z-index: 1;
  pointer-events: none; 
  border-radius: 10px;
  padding: 10px;
  background-color: rgba(31, 30, 30, 0.3);
  backdrop-filter: blur(5px);
}

.car-information-children{
  width: 25%;
  display: flex;
  flex-direction: column;
  text-align: center;
  user-select: none;
}
.text-normal{
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  pointer-events: visible;
}
.text-number{
  color: #ff0000;
  font-size: 50px;
  margin: 0px;
}
.unit-area{
  display: flex;
  justify-content: space-between;
  padding-right: 35px;
  padding-left: 35px;
}

@media only screen and (max-width: 700px) {
  .car-information{
    position: absolute;
    bottom: 0px;
    right: 0px;
    display: flex;
    width: 100%;
    z-index: 1;
    pointer-events: none; 
    background-color: rgba(31, 30, 30, 0.3);
    backdrop-filter: blur(5px);
  }
  .text-normal{
    color: #ffffff;
    font-size: 11px;
    cursor: pointer;
    pointer-events: visible;
  }
  .text-number{
    color: #ff0000;
    font-size: 38px;
    margin: 0px;
  }
  .unit-area{
    display: flex;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
  }
}


.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 0%;
  transform: translateY(-50%);
  left: 50%;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.information-btn {
  border-radius: 100%;
  cursor: pointer;
}

.information-btn-text {
  color: #3cc1ff;
  font-size: 5px;
}